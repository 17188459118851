<template>
	<div>
		<v-row>
			<v-col>
				<div class="text-h4">{{ title }}</div>
				<p v-if="desc" class="text-body-2">{{ desc }}</p>
			</v-col>
			<v-col>
				<v-btn fab color="warning" class="float-right" :title="'Aggiungi ' + itemName" @click="newItem">
					<v-icon>mdi-plus</v-icon>
				</v-btn>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-alert type="warning" text outlined dense>Questo è un editor generico senza controlli e validazioni sui dati. Prestare particolare attenzione nell'inserimento e nelle modifiche, soprattutto dei campi "codice*"</v-alert>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-data-table
					:items="items"
					:headers="tableHeaders"
					@click:row="editItem"
				></v-data-table>
			</v-col>
		</v-row>
		<!-- inizio dialog -->
		<v-dialog v-if="currentItem" v-model="dialog" :fullscreen="$vuetify.breakpoint.name === 'xs'" persistent transition="scale-transition" max-width="800" @keydown.esc="dialog = false">
			<v-card>
				<v-toolbar dense :color="appSetup.appColor">
					<v-btn icon @click="dialog = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>{{ currentItem.Id > 0 ? 'Modifica' : 'Nuovo/a' }} {{ itemName }}</v-toolbar-title>
					<v-spacer />
					<v-toolbar-items>
						<v-btn text dark :loading="loading" @click="updateItem(currentItem)">
							Save
						</v-btn>
					</v-toolbar-items>
				</v-toolbar>
				<v-container fluid>
					<v-row>
						<v-col v-for="(column, index) in columns" :key="index">
							<!-- DataType = nvarchar -->
							<v-text-field v-if="column.DataType==='nvarchar'" v-model="currentItem[column.ColumnName]" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="column.ColumnName" />
							<!-- DataType = int -->
							<v-text-field v-if="column.DataType==='int'" v-model="currentItem[column.ColumnName]" type="number" class="text-right" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :label="column.ColumnName" />
							<!-- DataType = bit -->
							<v-switch v-if="column.DataType==='bit'" v-model="currentItem[column.ColumnName]" :label="column.ColumnName"></v-switch>
						</v-col>
						<!--
						<v-col cols="12" lg="2">
							
						</v-col>
						<v-col cols="12" lg="10">
							<v-text-field v-model="currentItem.DescrizioneSede" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="Descrizione Sede" />
						</v-col>
						<v-col cols="12" lg="12">
							<v-text-field v-model="currentItem.Indirizzo" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="Indirizzo Sede" />
						</v-col>
						<v-col cols="6">
							<v-text-field v-model="currentItem.IndirizzoLat" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="Latitudine Indirizzo Sede" />
						</v-col>
						<v-col cols="6">
							<v-text-field v-model="currentItem.IndirizzoLon" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="Longitudine Indirizzo Sede" />
						</v-col>
						-->
					</v-row>
					<v-row v-if="error">
						<v-col>
							<v-alert type="error" outlined>
								<div class="title">Error</div>
								<div class="test-caption">{{ error }}</div>
							</v-alert>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-dialog>									
		<!-- fine dialog -->	
	</div>
</template>


<script>
// ***********************************************************************************
// ** AdminDecodifiche - GENERICO !
// ***********************************************************************************
export default {
	name: 'AdminDecodificheGeneric',
	props: {
		// titolo globale del componente di editing (ad esempio per la tabella Sedi sarà appunto sedi)
		title: {
			type: String,
			required: true,
		},
		desc: {
			type: String,
			default: () => {return ''}
		},
		// nome del singolo item (ad esempio per la tabella Sedi sarà sede )
		itemName: {
			type: String,
			required: true,
		},
		// identificativo della tabella del db su cui lavorare. 
		tableName: {
			type: String,
			required: true,
		},
	},
	data: () => {
		return {
			dialog: false,
			loading: false,
			columns: [],
			items: [],
			currentItem: {},
			currentItemIndex: -1,
			error: null,
		}
	},
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },
		darkTheme() { return this.$vuetify.theme.dark },
		tableHeaders() {
			return this.columns.map( x => { return { text: x.ColumnName, value: x.ColumnName } })
		}
	},
	watch:{
		tableName() {
			this.loadItems()
		}
	},
	mounted() {
		
	},
	methods: {
		loadItems() {
			this.$store.dispatch('genericApiPost', {apiUrl: 'setup/decodifiche/generic/list', value: {TableName: this.tableName}}).then((response) => {
				this.items = response.Items
				this.columns = response.Columns
			}).catch((error) => {
				console.error(error)
			})
		},
		async newItem() {
			this.loading = true
			var negativeId = 0
			await this.$store.dispatch('getNegativeIncrementId').then(id => { negativeId = id }) // chiamata sincrona allo store !
			this.$store.dispatch('genericApiPost', {apiUrl: 'setup/decodifiche/generic/new', value: {TableName: this.tableName}}).then((response) => {
				this.currentItem = response
				this.currentItem.Id = negativeId
				this.dialog=true
			}).catch((error) => {
				console.error(error)
			}).finally(()=> {
				this.loading = false
			})
			
		},
		editItem(item) {
			this.currentItem = JSON.parse(JSON.stringify(item))
			this.dialog=true
		},
		updateItem() {
			this.error= null
			this.loading = true
			this.$store.dispatch('genericApiPost', {apiUrl: 'setup/decodifiche/generic/set', value: {TableName: this.tableName, Item: this.currentItem}}).then((response) => {
				this.items = response.Items
				this.dialog=false
			}).catch((error) => {
				console.error(error)
				this.error= error
			}).finally(()=> {
				this.loading = false
			})
		},
	}
}
</script>


<style scoped lang="less">

</style>