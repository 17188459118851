<template>
	<div>
		<v-row>
			<v-col cols="6" md="4" lg="3">
				<v-card width="250">
					<v-navigation-drawer permanent>
						<v-list>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title class="title">Tabelle di decodifica</v-list-item-title>
									<v-list-item-subtitle>click & edit</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</v-list>

						<v-divider></v-divider>

						<v-list dense>
							<v-list-item-group v-model="currentTable" color="accent">
								<v-list-item v-for="(tabella, idx) in tabelle" :key="idx">
									<v-list-item-content>
										<v-list-item-title>{{ tabella.title }}</v-list-item-title>
										<v-list-item-subtitle>{{ tabella.desc }}</v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>
							</v-list-item-group>
						</v-list>

						<v-divider></v-divider>

						<v-list>
							<v-list-item>
								<v-list-item-content>
									<v-alert color="warning" outlined dense prominent text class="text-caption">
										Dopo aver modificato le tabelle di decodifica è consigliabile uscire e rientrare (logout - login) per vederne i valori aggiornati nel resto dell'applicativo
									</v-alert>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-navigation-drawer>
				</v-card>
			</v-col>
			<v-col>
				<div v-if="componentToLoad">
					<keep-alive>
						<component :is="componentToLoad" v-bind="componentParameters"></component>
					</keep-alive>
				</div>
			</v-col>
		</v-row>
	</div>
</template>


<script>
// ***********************************************************************************
// ** ADMIN - Manage delle tabelle di decodifica
// ***********************************************************************************
// possiamo usare sia il componente generic "admin-decodifiche-generic" al quale va passato sostanzialmente solo il nome della tabella del db
// oppure possiamo usare dei componenti specializzati (vedi quello per le sedi ad esempio)
import AdminDecodificheSedi from '@/components/admin/admin-decodifiche-sedi'
import AdminDecodificheGeneric from '@/components/admin/admin-decodifiche-generic'
// import AdminDecodificheLoading from '@/components/admin-decodifiche/loading'
// import AdminDecodificheError from '@/components/admin-decodifiche/error'
export default {
	name: 'AdminDecodifiche',
	components: {  AdminDecodificheSedi, AdminDecodificheGeneric }, //AdminDecodificheLoading, AdminDecodificheError,
	props: {
		
	},
	data: () => {
		return {
			tabelle: [
				{title:'Sedi', desc:'Sedi dell\'azienda e geolocalizzazione', component:'admin-decodifiche-sedi'},
				{title: 'Reparti e gruppi', desc:'Reparti dell\'azienda', component:'admin-decodifiche-generic', parameters: {
					itemName: 'reparto-gruppo',
					tableName: 'RepartoGruppoes',
				}},
				{title: 'Aree inserimento', desc:'Aree di inserimento delle persone in azienda', component:'admin-decodifiche-generic', parameters: {
					itemName: 'area',
					tableName: 'AreaInserimentoes',
				}},
				{title: 'Aree di competenza', desc:'Aree di competenza per i jobs', component:'admin-decodifiche-generic', parameters: {
					itemName: 'area',
					tableName: 'Areas',
				}},
				{title: 'Livelli inquadramento', desc:'Livelli di inquadramento in azienda', component:'admin-decodifiche-generic', parameters: {
					itemName: 'livello',
					tableName: 'CategoriaLivelloInquadramentoes',
				}},
				{title: 'Tipologie competenza', desc:'Tipologie competenza', component:'admin-decodifiche-generic', parameters: {
					itemName: 'tipo',
					tableName: 'TipoCompetenzas',
				}},
				{title: 'Settori competenze', desc:'Settori competenze', component:'admin-decodifiche-generic', parameters: {
					itemName: 'settore',
					tableName: 'Settores',
				}},
				{title: 'Livelli competenza', desc:'Livelli competenza', component:'admin-decodifiche-generic', parameters: {
					itemName: 'livello',
					tableName: 'Livelloes',
				}},
			],
			currentTable: undefined,
		}
	},
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },
		darkTheme() { return this.$vuetify.theme.dark },
		decodifiche() { return this.$store.getters['decodifiche'] },
		componentToLoad() {
			return this.currentTable !== undefined ? this.tabelle[this.currentTable].component : null
		},
		componentParameters() {
			if(this.currentTable !== undefined) { 
				var parametri = this.tabelle[this.currentTable].parameters
				if(!parametri) parametri = {}
				parametri.title = this.tabelle[this.currentTable].title
				parametri.desc = this.tabelle[this.currentTable].desc
				return parametri
			} else {
				return null
			}
		},
	},
}
</script>