<template>
	<div>
		<div class="mb-5">
			<div class="text-h5  ">Esportazione tabelle</div>
			<div class="text-caption  mt-1">
				Le tabelle selezionate verranno esportate in formato .xlsx nella cartella:<br>
				<v-chip x-small label>"{{ exportPath }}"</v-chip>
			</div>
		</div>
		<v-list>
			<template v-for="(item, index) in exportTableList">
				<v-list-item :key="index">
					<v-list-item-action>
						<v-checkbox v-model="item.Selected" />
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title>{{ item.Name }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</template>
		</v-list>
		<v-btn color="warning" class="mb-5 mt-5" :disabled="loading" :loading="loading" @click="executeExport">
			Export
		</v-btn>
		<v-alert v-if="exportResult" type="success">
			{{ exportResult }}
		</v-alert>
		<v-alert v-if="exportError" type="error">
			{{ exportError }}
		</v-alert>



		<loading :loading="loading" />
	</div>
</template>

<script>

import Loading from '@/components/loading'
export default {
	name: 'AdminExportPage',
	components: {  Loading },
	data: () => ({
		currentTab: 0,
		loading: false,
		importPath: '',
		exportPath: '',
		importTableList: [],
		exportTableList: [],
		importResult: '',
		exportResult: '',
		importError: '',
		exportError: '',

	}),
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },
	},
	mounted() {
		this.fetchData()
	},
	methods: {
		fetchData() {
			this.loading = true
			this.$store.dispatch('genericApiPost', {apiUrl: '/ImportExport/Init'}).then((data) => {
				this.importPath = data.ImportPath
				this.exportTableList = data.ExportTableList.filter(item => {return !item.Reserved})
				this.exportPath = data.ExportPath
				this.importTableList = data.ImportTableList.filter(item => {return !item.Reserved})
				this.loading = false
			})
		},
		executeExport() {
			this.loading = true
			this.exportError = ''
			this.exportResult = ''
			this.$store.dispatch('genericApiPost', {apiUrl: '/ImportExport/Export', value: this.exportTableList}).then((data) => {
				console.warn('export', data)
				this.exportResult = 'Export completato'
				this.loading = false
			}).catch(error => {
				console.error('export', error)
				this.exportError = error
				this.loading = false
			})
		},
		executeImport() {
			this.loading = true
			this.importError = ''
			this.importResult = ''
			this.$store.dispatch('genericApiPost', {apiUrl: '/ImportExport/Import', value: this.importTableList}).then((data) => {
				console.warn('import', data)
				this.importResult = data
				this.loading = false
			}).catch(error => {
				console.error('import', error)
				this.importError = error
				this.loading = false
			})
		}
	}
}
</script>

<style scoped>

.v-list-item {
	min-height: auto !important;
	
}
.v-list-item__action , .v-list-item__content{
	padding-top: 0;
	padding-bottom: 0;
	margin-top: 2px;
	margin-bottom: 2px;
	margin-right: 8px !important;
}

</style>