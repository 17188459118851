<template>
	<v-overlay :value="loading">
		<v-progress-circular indeterminate size="64" />
	</v-overlay>
</template>


<script>
// ***********************************************************************************
// ** loading a tutta pagina con overlay e spin
// ***********************************************************************************
export default {
	name: 'LoadingComponent',
	props: {
		// 
		value: {
			type: String,
			default: () => {return ''}
		},
		loading: {
			type: Boolean,
			default: () => {return false}
		} 
	}
}
</script>