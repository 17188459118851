<template>
	<div>
		<v-row>
			<v-col cols="8">
				<div class="mb-5">
					<div class="text-h5  ">Gestione aziende</div>
					<div class="text-caption  mt-1">&nbsp;</div>
				</div>
			</v-col>
			<v-col>
				<v-btn fab color="warning" class="float-right" title="Aggiungi azienda" @click="newItem">
					<v-icon>mdi-plus</v-icon>
				</v-btn>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-data-table
					:items="itemList"
					:headers="tableHeaders"
					:loading="loading"
					disable-pagination
					@click:row="editItem"
				>
					<template v-slot:item.Default="{ item }">
						<v-chip v-if="item.Default" x-small label color="info">default</v-chip>
						<v-chip v-if="!item.Visibile" x-small label color="error">non visibile</v-chip>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
		<!-- inizio dialog -->
		<v-dialog v-if="currentItem" v-model="dialog" :fullscreen="$vuetify.breakpoint.name === 'xs'" persistent transition="scale-transition" max-width="800" @keydown.esc="dialog = false">
			<v-card>
				<v-toolbar dense :color="appSetup.appColor">
					<v-btn icon @click="dialog = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>{{ currentItem.Id>0 ? 'Modifica' : 'Nuova' }} azienda</v-toolbar-title>
					<v-spacer />
					<v-toolbar-items>
						<v-btn text dark @click="updateItem(currentItem)">
							Save
						</v-btn>
					</v-toolbar-items>
				</v-toolbar>
				<v-container fluid>
					<v-row>
						<v-col md="8">
							<v-text-field v-model="currentItem.Nome" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="Nome" />
						</v-col>
						<v-col md="4">
							<v-switch v-if="!currentItem.Default" v-model="currentItem.Visibile" label="visibile"></v-switch>
						</v-col>
						<v-col md="12">
							<v-textarea v-model="currentItem.Descrizione" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="Descrizione" rows="2" auto-grow />
						</v-col>
						<v-col md="12">
							<v-select v-model="currentItem.Lingua" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" :items="languagesTable" item-value="Id" item-text="Desc" label="Lingua di default" />
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-dialog>									
		<!-- fine dialog -->		
	</div>
</template>

<script>
/***************************************************************************************************************************************/
/** COMPONENTE SETUP - GESTIONE AZIENDE ******************************************************************************************************/
/***************************************************************************************************************************************/

export default {
	name: 'AdminAziende',
	components: {  },
	data: () => {
		return {
			loading: false,
			dialog: false,
			itemList: [],
			currentItem: {},
			tableHeaders:[
				{ text: 'Id', value: 'Id' },
				{ text: 'Nome', value: 'Nome' },
				{ text: 'Lingua default', value: 'Lingua' },
				{ text: '', value: 'Default' },
			],
		}
	},
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },
		languagesTable() { return this.$store.getters['translation/languagesTable'] },
	},
	mounted() {
		this.loadItems()
	},	
	methods: {
		loadItems() {
			this.loading=true
			this.$store.dispatch('genericApiPost', {apiUrl:'Aziende/List'}).then(response => {
				this.itemList = response
			}).finally(() => {
				this.loading=false
			})
		},
		async newItem() {
			var negativeId = 0
			await this.$store.dispatch('getNegativeIncrementId').then(id => { negativeId = id }) // chiamata sincrona allo store !
			this.currentItem = {
				Id: negativeId,
				Nome: '',
				Descrizione: '',
				Logo: '',
				Default: false,
				Visibile: true,
			}
			this.dialog=true
		},
		editItem(item) {
			this.currentItem = JSON.parse(JSON.stringify(item))
			this.dialog=true
		},
		updateItem(item) {
			this.dialog=false
			this.loading=true
			this.$store.dispatch('genericApiPost', {apiUrl: 'Aziende/Save', value: item}).then(response => {
				this.itemList = response
			}).catch((error) => {
				console.error(error)
			}).finally(() => {
				this.loading=false
			})
		},
	}
}
</script>
