<template>
	<div>
		<v-row>
			<v-col>
				<div class="text-h4">{{ title }}</div>
				<p v-if="desc" class="text-body-2">{{ desc }}</p>				
			</v-col>
			<v-col>
				<v-btn fab color="warning" class="float-right" title="Aggiungi sede" @click="newItem">
					<v-icon>mdi-plus</v-icon>
				</v-btn>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-list two-line>
					<template v-for="(item, index) in decodifiche.Sedi">
						<v-list-item :key="index" @click="editItem(item, index)">
							<v-list-item-avatar :class="item.Visibile ? '' : 'non-visibile'">
								<h1>{{ item.CodiceSede }}</h1>
							</v-list-item-avatar>
							<v-list-item-content :class="item.Visibile ? '' : 'non-visibile'">
								<v-list-item-title v-text="item.DescrizioneSede" />
								<v-list-item-subtitle v-text="item.Indirizzo" />
							</v-list-item-content>
							<v-list-item-action>
								<v-list-item-action-text :class="item.Visibile ? '' : 'non-visibile'">
									<v-icon v-if="item.IndirizzoLat && item.IndirizzoLon" title="Coordinate inserite" color="success">mdi-map-marker-check-outline</v-icon>
									<v-icon v-else title="Coordinate mancanti" color="error">mdi-map-marker-alert-outline</v-icon>
								</v-list-item-action-text>
							</v-list-item-action>
							<v-list-item-action>
								<v-btn small icon :title="item.Visibile ? 'Nascondi la sede' : 'Riabilita la sede nascosta'" @click.stop="toggleVisibile(item, index)">
									<v-icon v-if="!item.Visibile" color="error">mdi-eye</v-icon>
									<v-icon v-else color="grey darken-3">mdi-eye-off-outline</v-icon>
								</v-btn>
							</v-list-item-action>
						</v-list-item>
						<v-divider :key="index+'div'" />
					</template>
					<v-list-item v-if="!decodifiche.Sedi || decodifiche.Sedi.length==0">
						<v-list-item-content>
							<v-list-item-content class="text-center text-caption">Non ci sono record</v-list-item-content>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-col>
		</v-row>
		<!-- inizio dialog -->
		<v-dialog v-if="currentItem" v-model="dialog" :fullscreen="$vuetify.breakpoint.name === 'xs'" persistent transition="scale-transition" max-width="800" @keydown.esc="dialog = false">
			<v-card>
				<v-toolbar dense :color="appSetup.appColor">
					<v-btn icon @click="dialog = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>{{ currentItem.Id > 0 ? 'Modifica' : 'Nuova' }} sede</v-toolbar-title>
					<v-spacer />
					<v-toolbar-items>
						<v-btn text dark :loading="loading" @click="updateItem(currentItem)">
							Save
						</v-btn>
					</v-toolbar-items>
				</v-toolbar>
				<v-container fluid>
					<v-row>
						<v-col cols="12" lg="2">
							<v-text-field v-model="currentItem.CodiceSede" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="Codice Sede" :disabled="currentItem.Id > 0" />
						</v-col>
						<v-col cols="12" lg="10">
							<v-text-field v-model="currentItem.DescrizioneSede" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="Descrizione Sede" />
						</v-col>
						<v-col cols="12" lg="12">
							<v-text-field v-model="currentItem.Indirizzo" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="Indirizzo Sede" />
						</v-col>
						<v-col cols="6">
							<v-text-field v-model="currentItem.IndirizzoLat" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="Latitudine Indirizzo Sede" />
						</v-col>
						<v-col cols="6">
							<v-text-field v-model="currentItem.IndirizzoLon" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="Longitudine Indirizzo Sede" />
						</v-col>
					</v-row>
					<v-row v-if="error">
						<v-col>
							<v-alert type="error" outlined>
								<div class="title">Error</div>
								<div class="test-caption">{{ error }}</div>
							</v-alert>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-dialog>									
		<!-- fine dialog -->	
	</div>
</template>


<script>
// ***********************************************************************************
// ** AdminDecodifiche - Sedi
// ***********************************************************************************
export default {
	name: 'AdminDecodificheSedi',
	props: {
		// titolo globale del componente di editing (ad esempio per la tabella Sedi sarà appunto sedi)
		title: {
			type: String,
			required: true,
		},
		desc: {
			type: String,
			default: () => {return ''}
		},
	},
	data: () => {
		return {
			dialog: false,
			loading: false,
			currentItem: {},
			currentItemIndex: -1,
			error: null,
		}
	},
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },
		darkTheme() { return this.$vuetify.theme.dark },
		decodifiche() { return this.$store.getters['decodifiche'] },
	},
	methods: {
		newItem() {
			this.$store.dispatch('genericApiPost', {apiUrl: 'setup/decodifiche/sedi/new'}).then((response) => {
				this.currentItem = response
				this.currentItemIndex = -1
				this.dialog=true
			}).catch((error) => {
				console.error(error)
			})
		},
		editItem(item, index) {
			this.currentItemIndex = index
			this.currentItem = JSON.parse(JSON.stringify(item))
			this.dialog=true
		},
		updateItem(item) {
			this.error= null
			this.loading = true
			var isNewItem = item.Id<=0
			this.$store.dispatch('genericApiPost', {apiUrl: 'setup/decodifiche/sedi/set', value: item}).then((sede) => {
				this.dialog=false
				if(isNewItem){
					// this.itemList.push(item)
					this.$store.dispatch('modifyDecodifica', {table: 'Sedi', op: 'add', value: sede})
				} else {
					this.$store.dispatch('modifyDecodifica', {table: 'Sedi', op: 'update', index: this.currentItemIndex, value: sede})
					// this.itemList.splice(item.Index, 1, item)
				}
			}).catch((error) => {
				console.error(error)
				this.error= error
			}).finally(()=> {
				this.loading = false
			})
		},
		toggleVisibile(item, index) {
			this.currentItemIndex = index
			this.currentItem = JSON.parse(JSON.stringify(item))
			this.currentItem.Visibile = !this.currentItem.Visibile
			this.updateItem(this.currentItem)
		},
	}
}
</script>


<style scoped lang="less">
	.non-visibile {
		opacity: 0.2;
	} 
	
</style>