<template>
	<standard-container title="setup" admin>
		<!-- header -->
		<template v-slot:header>
			<v-tabs v-model="currentTab" :show-arrows="true" background-color="transparent">
				<v-tab key="tabSitesetup">Sitesetup</v-tab>
				<v-tab key="tabDecodifiche">Decodifiche</v-tab>
				<v-tab key="tabAziende">Aziende</v-tab>
				<v-tab key="tabExport">Export</v-tab>
				<v-tab key="tabMail">Mail test</v-tab>
			</v-tabs>				
		</template>

		<!-- body slot -->		
		<v-row :dense="compactVisualization">
			<v-col cols="12">
				<v-tabs-items v-model="currentTab">
					<!-- tabSitesetup -->
					<v-tab-item key="tabSitesetup">
						<v-row :dense="compactVisualization">
							<v-col cols="12" md="4">
								<v-text-field v-model="searchKey" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="filter key" clearable />
							</v-col>
							<v-col cols="12" md="8">
								<v-text-field v-model="searchValue" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" label="filter value" clearable />
							</v-col>
						</v-row>

						<v-row v-for="(item, index) in filteredSetupKeyvalues" :key="index" :dense="compactVisualization" :class="{'changed': item.Changed}" class="item-row">
							<v-col cols="12" sm="4">
								<h3 class="key" :title="item.Key">
									{{ item.Key }} <v-btn v-if="item.Changed" x-small color="error" @click.prevent="undo(item)">UNDO</v-btn>
								</h3>
								<div class="grey--text text--darken-1">
									{{ item.Description }}
								</div>
							</v-col>
							<v-col cols="12" sm="8">
								<div class="value">
									<!-- gestione tipo valore: string (default), color (visualizza il selettore colori), text, int, double, bool, o un array json es: ["pippo", "pluto", "paperino"]  -->
									<!-- gestione tipo valore text -->
									<v-textarea v-if="item.ValueType==='text'" v-model="item.Value" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" single-line rows="1" auto-grow @change="update(item)" />
									<!-- gestione tipo valore int -->
									<v-text-field v-else-if="item.ValueType==='int'" v-model="item.Value" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" single-line type="number" @change="update(item)" />
									<!-- gestione tipo valore double -->
									<v-text-field v-else-if="item.ValueType==='double'" v-model="item.Value" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" single-line :rules="[rules.isDouble]" @change="update(item)" />
									<!-- gestione tipo valore bool -->
									<v-switch v-else-if="item.ValueType==='bool'" v-model="item.Value" true-value="true" false-value="false" color="warning" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" single-line @change="update(item)" />
									<!-- gestione tipo valore array, richiede che il campo contenga un array json valido e mostra quindi una select con i vari valori (in pratica ValueType non contiene come negli altri casi un identificativo bensi l'elenco dei valori da visualizzare in tendina) -->
									<v-select v-else-if="item.ValueType && item.ValueType.startsWith('[')" v-model="item.Value" :items="JSON.parse(item.ValueType)" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" single-line @change="update(item)" />
									<!-- gestione tipo valore tipo json -->
									<div v-else-if="item.ValueType && item.ValueType === 'json'" class="d-flex align-center">
										<v-textarea v-model="item.Value" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" single-line rows="1" auto-grow @change="update(item)" />
										<!-- TODO: serve un visualizzatore json che tenga conto degli errori (almeno se il json e valido o meno) -->
									</div>
									<!-- gestione tipo valore tipo icona mdi -->
									<div v-else-if="item.ValueType && item.ValueType === 'icon-mdi'" class="d-flex align-center">
										<v-icon x-large class="mr-10 mb-6">
											{{ item.Value }}
										</v-icon>
										<v-text-field v-model="item.Value" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" single-line @change="update(item)" />
									</div>
									<!-- gestione tipo valore color -->
									<v-row v-else-if="item.ValueType==='color'" no-gutter>
										<v-col lg="6">
											<v-color-picker v-model="item.Value" hide-mode-switch />
										</v-col>
										<v-col lg="6">
											<v-text-field v-model="item.Value" dense solo-inverted single-line />
											<v-btn color="error" @click="update(item)">
												SAVE
											</v-btn>
											<p class="mt-5">
												Salvare una volta selezionato il colore voluto
											</p>
											<p>E' possibile modificare direttamente il campo testuale, il picker si adatterà di conseguenza</p>
										</v-col>
									</v-row>
									<!-- gestione tipo valore string nonché default quando tipo non è valorizzato -->
									<v-text-field v-else v-model="item.Value" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" single-line @change="update(item)" />
								</div>
							</v-col>
							<v-col cols="12">
								<v-divider dark />
							</v-col>
						</v-row>
		
						<v-row :dense="compactVisualization">
							<v-col cols="12">
								<div v-if="!isEnabledImportJson">
									<v-btn class="btn btn-danger btn-sm " @click.prevent="enableImportJson">
										IMPORT
									</v-btn>
									<v-btn class="btn btn-info btn-sm" style="margin-left:10px" @click.prevent="exportJson">
										EXPORT
									</v-btn>
								</div>
								<div v-if="isEnabledImportJson" id="import-panel">
									<h3>Importa un file di configurazione.</h3>
									<p>
										Le impostazioni già presenti verranno sovrascritte o meno a seconda dell'impostazione del flag.<br>
										Le impostazioni non presenti verranno sempre caricate.
									</p>
									<json-upload v-model="jsonUpload" :enable-preview="true" />
									<br>
									<div v-if="jsonUpload">
										<v-list color="transparent">
											<v-list-item>
												<v-list-item-action>
													<v-checkbox v-model="jsonOverwrite" />
												</v-list-item-action>
												<v-list-item-content>
													<v-list-item-title>Overwrite</v-list-item-title>
													<v-list-item-subtitle v-if="jsonOverwrite">
														<strong>Overwrite attivo!</strong> Tutte le chiavi importate verranno caricate, sovrascrivendo quelle già presenti.
													</v-list-item-subtitle>
													<v-list-item-subtitle v-else>
														Overwrite disattivato. Verranno caricate solo le chiavi non presenti nel database.
													</v-list-item-subtitle>
												</v-list-item-content>
											</v-list-item>
											<v-list-item>
												<v-list-item-action>
													<v-checkbox v-model="deleteGlobal" />
												</v-list-item-action>
												<v-list-item-content>
													<v-list-item-title>Delete (global)</v-list-item-title>
													<v-list-item-subtitle v-if="deleteGlobal">
														<strong>Delete Global attivo!</strong> Tutte le chiavi globali "*" presenti nel database verranno cancellate prima dell'importazione.
													</v-list-item-subtitle>
													<v-list-item-subtitle v-else>
														Delete Global disattivato. Le chiavi presenti nel database non verranno cancellate prima dell'importazione.
													</v-list-item-subtitle>
												</v-list-item-content>
											</v-list-item>
										</v-list>
									</div>
									<div class="v-btns-row text-center">
										<v-btn class="error" :disabled="jsonUpload === ''" @click.prevent="importJson">
											OK, IMPORTA
										</v-btn>
										<v-btn class="info" style="margin-left:10px" @click.prevent="isEnabledImportJson = false">
											CANCELLA
										</v-btn>
										<div class="importError">
											{{ importError }}
										</div>
									</div>
								</div>
							</v-col>
						</v-row>
						<loading :loading="loading" />
					</v-tab-item>
					<!-- tabDecodifiche -->
					<v-tab-item key="tabDecodifiche">
						<admin-decodifiche></admin-decodifiche>
					</v-tab-item>
					<!-- tabAziende -->
					<v-tab-item key="tabAziende">
						<admin-aziende></admin-aziende>
					</v-tab-item>
					<!-- tabExport -->
					<v-tab-item key="tabExport">
						<admin-export></admin-export>
					</v-tab-item>
					<!-- tabMail -->
					<v-tab-item key="tabMail">
						<div>
							<div class="mb-5">
								<v-row :dense="compactVisualization">
									<v-col cols="12">
										<div class="text-h5  ">Email test</div>
										<div class="text-caption  mt-1">
											Consente di testare il meccanismo di invio di mail<br>
											E' possibile sperimentare liberamente diversi parametri ma per modificarli in modo permanente è comunque necessario usare il sitesetup.
										</div>
									</v-col>
								</v-row>
								<v-row :dense="compactVisualization" class="mt-6">
									<v-col cols="6">
										<v-text-field v-model="email.smtpAddress" label="Smtp address" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" />
									</v-col>
									<v-col cols="4">
										<v-text-field v-model="email.SmtpPort" type="numeric" label="Smtp port" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" />
									</v-col>
									<v-col cols="2">
										<v-switch v-model="email.smtpUseSsl" label="Smtp ssl" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" />
									</v-col>
									<v-col cols="6">
										<v-text-field v-model="email.smtpUsername" label="Smtp username" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" />
									</v-col>
									<v-col cols="6">
										<v-text-field v-model="email.smtpPassword" label="Smtp password" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" />
									</v-col>
								</v-row>
								<v-row :dense="compactVisualization" class="mt-6">
									<v-col cols="6">
										<v-text-field v-model="email.from" label="Mail from" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" />
									</v-col>
									<v-col cols="6">
										<v-text-field v-model="email.to" label="Mail to" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" />
									</v-col>
								</v-row>
								<v-row :dense="compactVisualization" class="mt-6">
									<v-col cols="12">
										<v-text-field v-model="email.subject" label="subject" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" />
										<v-textarea v-model="email.body" label="body" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" />
									</v-col>
								</v-row>
								<v-row :dense="compactVisualization" class="mt-6">
									<v-col cols="12">
										<v-alert v-if="emailResponse.showError" type="error" text outlined>
											<h4>Errore di invio</h4>
											<pre>{{ emailResponse.error }}</pre>
										</v-alert>
										<v-alert v-if="emailResponse.showSuccess" type="success" text>
											<h4>Invio riuscito</h4>
											<pre>{{ emailResponse.success }}</pre>
										</v-alert>
									</v-col>
								</v-row>
								<v-row :dense="compactVisualization" class="mt-6">
									<v-col cols="12">
										<div class="d-flex justify-space-between">
											<v-btn large color="default" @click="resetEmailParameters">reset</v-btn>
											<v-btn large color="accent" @click="testEmail">INVIA MAIL</v-btn>
										</div>
									</v-col>
								</v-row>
							</div>
						</div>
					</v-tab-item>
				</v-tabs-items>
			</v-col>
		</v-row>
	</standard-container>			
</template>

<script>
/***************************************************************************************************************************************************************************************************************
*** Attenzione che il comportamento di questa pagina è stato modificato rispetto alle precedenti versioni
*** in pratica non "lavora" più sui dati vuex ma solo in locale. Questo per due motivi: 
*			primo non si possono modifcare dati di vuex senza usare un mutatore (nelle versioni precedenti evidentemente vuex non controllava perche non era in strict mode)
*			e secondo perché visto che i dati vengono caricati solo in questa pagina è inutile che stiano a disposizione di altre pagine quando non è chiaro se esistono o meno
****************************************************************************************************************************************************************************************************************
*** Attenzione inoltre che il sito Zordan HR usa il casing di .NET per ragioni di compatibilità (quindi occhio ai vari Key Value, ecc)
****************************************************************************************************************************************************************************************************************/
function isInt(n){ return Number(n) === n && n % 1 === 0 }
function isFloat(n){ return Number(n) === n && n % 1 !== 0 }
/* PAGINA ADMIN - quindi non passiamo per variabili vuex per semplificare */
import StandardContainer from '@/components/standard-container'
import JsonUpload from '@/components/json-upload'
import Loading from '@/components/loading'
import AdminDecodifiche from '@/components/admin/admin-decodifiche'
import AdminAziende from '@/components/admin/admin-aziende'
import AdminExport from '@/components/admin/admin-export'
import moment from 'moment'
//
export default {
	name: 'AdminSetupPage',
	components: { StandardContainer, JsonUpload, Loading, AdminDecodifiche, AdminAziende, AdminExport },
	data: () => {
		return {
			currentTab: 'tabSitesetup',
			loading: true,
			setupKeyvalues: [],
			searchKey: '',
			searchValue: '',
			isEnabledImportJson: false,
			jsonUpload: '',
			jsonOverwrite: false,
			deleteAll: false,
			deleteGlobal: false,
			importError: '',
			rules: {
				isDouble: value => (!value || isInt(value) || isFloat(value)) || 'Inserire un numero valido', // todo: sistemare!
				required: value => !!value || 'Campo obbligatorio',
				min: v => v.length >= 8 || 'Almeno 8 caratteri'
			},
			email: {
				smtpAddress: '',
				SmtpPort: 0,
				smtpUseSsl: false,
				smtpUsername: '',
				smtpPassword: '',
				from: '',
				to: '',
				subject: '',
				body: '',
			},
			emailResponse: {
				showError: false,
				showSuccess: false,
				error: '',
				success: '',
			}
		}
	},
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },
		currentUser() { return this.$store.getters['currentUser'] },
		filteredSetupKeyvalues() {
			return this.setupKeyvalues.filter(keyvalue => {
				var fk = keyvalue.Key && (!this.searchKey || keyvalue.Key.toLowerCase().indexOf(this.searchKey.toLowerCase()) >= 0)
				var fv = keyvalue.Value && (!this.searchValue || keyvalue.Value.toLowerCase().indexOf(this.searchValue.toLowerCase()) >= 0)
				return fk && fv
			})
		}
	},
	mounted() {
		// carica tutti i setup globali 
		// todo: in futuro gestire anche quelli per i singoli utenti  (basta creare una fuinzione che restituisa la lista di quelli effettivamente usati, con realtiva decondifca del nome e tipo di utente)
		this.fetchData()
		
	},
	methods: {
		fetchData() {
			this.loadSetupKeyvalues().then((response) => {
				this.setupKeyvalues = response
				this.resetEmailParameters()
				this.loading = false
			})
		},
		update(item) {
			this.setKeyvalue(item).then(() => {
				item.Changed = true
			}).catch((error) => {
				console.error(error)
			})
		},
		undo(item) {
			item.Value = item.OldValue
			this.setKeyvalue(item).then(() => {
				item.Changed = false
			}).catch((error) => {
				console.error(error)
			})
		},
		// esporta la lista filtrata corrente in formato json
		exportJson() {
			var a = document.createElement('a')
			var file = new Blob([JSON.stringify(this.filteredSetupKeyvalues, null, 4)], {type: 'application/json'})
			a.href = URL.createObjectURL(file)
			var full = this.filteredSetupKeyvalues.length === this.setupKeyvalues.length
			a.download = 'hr-setup_' + (full ? 'full' : 'filtered') + '_' + moment().format('YY-MM-DD') + '.json' 
			a.click()
		},
		enableImportJson() {
			this.$swal('ATTENZIONE!', 'Le impostazioni potrebbero essere sovrascritte da quelle importate.\nSe non sai cosa stai facendo lascia stare!', 'error', {
				buttons: {
					ok: 'Ok, procedi',
					cancel: 'No no, per carità!'
				}
			}).then(result => {
				if (result === 'ok') {
					this.isEnabledImportJson = true
					setTimeout(function() { window.scrollTo(0, 99999) }, 10)
				}
			}).catch(() => {})
		},
		importJson() {
			this.setupImportJson({Keyvalues: JSON.parse(this.jsonUpload), Overwrite: this.jsonOverwrite, DeleteGlobal: this.deleteGlobal}).then( () => {
				this.fetchData()
				this.jsonUpload = ''
				this.isEnabledImportJson = false
			}).catch((error) => {
				this.importError = error
				console.error(error)
			})
		},
		getKeyValue(key) {
			var ukv = this.setupKeyvalues.find(keyvalue => {
				return keyvalue.Key.toLowerCase() == key.toLowerCase()
			})
			return ukv ? ukv.Value : ''
		},
		// resetta i parametri della mail per il test (li preleva semplicemente dalle chiavi di setup)
		resetEmailParameters() {
			this.email = {
				smtpAddress: this.getKeyValue('setup.mail.smtp-address'),
				SmtpPort: parseInt(0+this.getKeyValue('setup.mail.smtp-port')),
				smtpUseSsl: (this.getKeyValue('setup.mail.smtp-ssl') == 'true'),
				smtpUsername:  this.getKeyValue('setup.mail.smtp-username'),
				smtpPassword:  this.getKeyValue('setup.mail.smtp-password'),
				from:  this.getKeyValue('setup.mail.default-from'),
				to:  this.getKeyValue('setup.formazione.notifica.mail.to'), // this.getKeyValue('setup.mail.default-from'),
				subject: 'HR Email Test',
				body: 'Mail di test inviata da HR Zordan',
			}
		},
		testEmail() {
			this.emailResponse.showError = false
			this.emailResponse.showSuccess = false
			this.emailResponse.error = ''
			this.$store.dispatch('genericApiPost', {apiUrl: '/setup/mailtest/send', value: this.email}).then((response) => {
				this.emailResponse.success = response
				this.emailResponse.showSuccess = true
			}).catch((error) => {
				this.emailResponse.error = error
				console.error('post /setup/mailtest/send', error)
				this.emailResponse.showError = true
			})
		},
		// .................................................................
		// ......... GESTIONE SETUP KEY VALUES ADMIN .......................
		// .................................................................
		// carica tutte le keyvalues (inteso come tutte quelle connesse all'azienda corrente)
		loadSetupKeyvalues() {
			return this.$store.dispatch('genericApiPost', {apiUrl: '/setup/keyvalue/list'})
		},
		// salva una keyvalue
		setKeyvalue(ukv) {
			return new Promise((resolve) => {
				this.$store.dispatch('genericApiPost', {apiUrl: '/setup/keyvalue/set', value: ukv}).then((response) => {
					resolve(response)
				})
			})
		},
		// importa un nuovo set di setup
		setupImportJson(data) {
			return this.$store.dispatch('genericApiPost', {apiUrl: '/setup/keyvalue/import', value: data})
		},	



	}
}
</script>


<style scoped lang="less">

    #import-panel{
        padding: 20px;
        background-color: #5e2f35;
        border:dashed 2px yellow;
        box-shadow: 1px 1px 10px #222;
        border-radius: 10px;
        min-height: 700px;
        position:relative;
        .v-btns-row{
            height: 60px;
            position:absolute;
            bottom:0;
            left:0;
            right:0;
        }
        .option-row{
            margin-top: 20px;
            position:relative;
            .option-row-v-btns {
                float:left;
                width:100px;
            }
            .option-row-text {
                float:left;
                left:100px; 
                padding-top:5px;
            }
        }
	}

</style>